.accordion__item {
  .accordion__header {
    margin-bottom: 2rem;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin: 2rem 0;
      background-color: currentColor;
      opacity: 0.2;

      @screen m {
        margin-top: 3rem;
      }
    }

    button {
      max-width: 70%;
      outline: none;

      @screen s {
        max-width: 80%;
      }
    }

    @screen m {
      margin-bottom: 3rem;
    }
  }

  &:last-child {
    .accordion__header {
      &::after {
        content: unset;
      }
    }
  }
  .list-disc{
    list-style-type: disc;
  }
}

.accordion__item[data-option-is-open] div[data-ref='container'] {
  overflow: visible;
}

.accordion__btn-line {
  @apply transform -translate-x-1/2 scale-50 transition-all duration-500 ease-out-expo;
  position: absolute;
  left: 50%;
  transition: transform 500ms cubic-bezier(0.19, 1, 0.22, 1);

  @screen m {
    @apply scale-100;
  }
}

.accordion__header--open .accordion__btn-line--plus {
  @apply transform -translate-x-1/2 rotate-90 scale-50;

  @screen m {
    @apply scale-100;
  }
}

/*------------------------------------*
  Agency grid

  @note the grid is the same as the .main-content class in order to be able to align images on the
  existing grid the only difference is that instead of having a 9fr in the middle,
  we have repeat(12, 0.75fr) which is dividing the 9fr into 12 columns

  @todo mobile grid, see with @lucas.w
*------------------------------------*/
.agency-grid {
  display: grid;
  grid-template-areas: 'r r r r r r r r r r r r';
  grid-template-rows: 1fr;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 0px;
  width: 100%;

  @screen s {
    // @note next line is disable to have prevent linting from having a line after the semi colon,
    // in order to have the grid visually align in the code.
    /* stylelint-disable-next-line declaration-colon-space-after */
    grid-template-areas:
      '. . . . . . . . t t t t t . . . .'
      '. . . . . . . . t t t t t . . . .'
      'l l l l l l . . t t t t t . . . .'
      'l l l l l l . . . . . . . . . . .'
      'l l l l l l . . r r r r r r r r r'
      'l l l l l l . . r r r r r r r r r'
      '. . . . . . . . r r r r r r r r r'
      '. . . . . . . . r r r r r r r r r';
    grid-template-rows: repeat(8, minmax(1fr, 20px));
    grid-template-columns: 4rem 1fr 1fr repeat(12, 0.75fr) 1fr 4rem;
  }

  .figure {
    height: 100%;
    overflow: hidden;

    // @note applying grid to align the images to the text above
    @screen s {
      @apply grid;
    }

    > div {
      height: 100%;
    }
  }
}

.agency-grid__left {
  display: none;
  grid-area: l;

  @screen s {
    display: block;
  }

  .figure {
    padding-left: 0 !important;
  }
}

.agency-grid__top {
  display: none;
  grid-area: t;

  @screen s {
    display: block;
  }
}

.agency-grid__right {
  grid-area: r;

  // @note no right padding to fit to the screen
  .figure {
    padding-right: 0 !important;
  }
}

.team {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.75rem));
  grid-gap: 1.5rem;

  @screen s {
    grid-template-columns: repeat(2, 50%);
  }

  @screen m {
    grid-template-columns: repeat(3, 33.333%);
  }
}

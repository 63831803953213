.wrapper {
  z-index: 1;
  position: relative;

  .main-content,
  .main-footer__content {
    display: grid;
    grid-template-columns: 1fr;

    @screen xs {
      grid-template-columns: 1fr;
    }

    @screen s {
      grid-template-columns: 9.5rem 1fr 4rem;
    }

    @screen m {
      grid-template-columns: 4rem 1fr 1fr 9fr 1fr 4rem;
    }

    > * {
      grid-column: 1;

      @screen s {
        grid-column: 2;
      }

      @screen m {
        grid-column: 4;
      }
    }

    > .full-bleed {
      grid-column: 1 / -1;
      width: 100%;
    }
  }
}

/*------------------------------------*
  Full Bleed Image
*------------------------------------*/

.full-bleed-image {
  padding-bottom: 6rem;

  @screen s {
    padding-bottom: 12rem;
  }

  &.full-bleed-image--sub-hero {
    .figure {
      &:before {
        transform: scaleY(1);
        transform-origin: top;
      }
    }

    .is-loaded {
      &.figure:before {
        transform: scaleY(0);
      }
    }
  }
}

.full-bleed-image + section.py-24 {
  padding-top: 0;
}

/*------------------------------------*
  Footer
*------------------------------------*/

.footer {
  .footer-border {
    display: none;
  }
}

.push-contact {
  ~ .footer {
    .footer-border {
      display: block;
    }
  }
}

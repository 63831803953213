/*------------------------------------*
  Bleeding image
*------------------------------------*/
.bleeding-image {
  display: grid;
  grid-template-areas: 'img img img img img img img';
  grid-template-rows: 1fr;
  grid-template-columns: 4rem 1fr 1fr 9fr 1fr 4rem;
  gap: 0px 0px;
  width: 100%;

  @screen s {
    grid-template-areas: '. . . img img img img';
  }
}

.bleeding-image__col {
  grid-area: img;

  // @note no right padding to fit to the screen
  .figure {
    @apply grid;
    padding-right: 0 !important;
  }
}

/*------------------------------------*
  Right align variant
  @note this class is currently not used
*------------------------------------*/
.bleeding-image--right {
  @screen s {
    grid-template-areas: 'img img img img . . .';
  }
}

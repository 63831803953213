/*------------------------------------*
  Cursor
*------------------------------------*/
.cursor {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  pointer-events: none;
  mix-blend-mode: difference;
  transform: translateZ(0px) matrix(0, 0, 0, 0, -32, -32);

  @apply w-8 h-8 -ml-4 -mt-4;

  .icon--cursor {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .icon--arrow {
    width: 25px;
    height: 7px;
    color: #fff;
    opacity: 0;
    transform: translate3d(-100%, 0, 0) scale(0.5);
    transition:
      0.4s opacity theme('transitionTimingFunction.out-quad'),
      0.4s transform theme('transitionTimingFunction.in-out-quad');

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .icon--external {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    color: #fff;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
    transition:
      0.4s opacity theme('transitionTimingFunction.out-quad'),
      0.4s transform theme('transitionTimingFunction.in-out-quad');

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.has-arrow {
    color: #222;
    mix-blend-mode: unset;

    .icon--arrow {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0.5);
      transition:
        0.4s opacity theme('transitionTimingFunction.out-quad'),
        0.4s transform theme('transitionTimingFunction.in-out-quad');
    }
  }

  &.has-external {
    color: #222;
    mix-blend-mode: unset;

    .icon--external {
      opacity: 1;
      transform: translate(-50%, -50%) scale(0.5);
      transition:
        0.4s opacity theme('transitionTimingFunction.out-quad'),
        0.4s transform theme('transitionTimingFunction.in-out-quad');
    }
  }
}

/*------------------------------------*
  Slider
*------------------------------------*/
.slider {
  position: relative;
  // @note Ratio from the content, since .slider__grid is position: absolute;
  padding-top: 150%;

  @screen s {
    padding-top: 72%;
  }

  @screen m {
    padding-top: 66%;
  }

  @screen l {
    padding-top: 59%;
  }

  @screen xxl {
    padding-top: 55%;
  }
}

.slider--case-study {
  // @todo: find a proper way to manage slider height

  padding-top: 147.3%;

  @screen s {
    padding-top: 130.75396825%;
  }

  @screen m {
    padding-top: 69.60784314%;
  }

  @screen l {
    padding-top: 71.30904184%;
  }

  @screen xxl {
    padding-top: 45.88302101%;
  }
}

.slider__grid {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(999, minmax(75%, 384px));
  grid-auto-flow: column;
  grid-gap: 1rem;
  width: 100%;
  cursor: grab;

  &.cursor-grabbing {
    cursor: grabbing;
  }

  @screen s {
    grid-template-columns: repeat(999, 33.33%);
    grid-gap: 3rem;
  }
}

.slider__grid--case-study {
  grid-template-columns: repeat(999, 75.33%);
  grid-gap: 3rem;
  min-width: 100vw;

  // @todo: find a proper way to manage slider height
  @screen s {
    grid-template-columns: repeat(999, 65%);
    grid-gap: 6.5rem;
    min-width: auto;
  }

  @screen m {
    grid-template-columns: repeat(999, 35%);
    grid-gap: 8.5rem;
  }

  @screen xxl {
    grid-template-columns: repeat(999, 22.5%);
    grid-gap: 12.5rem;
  }
}

.slider__progress {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: rgba(250, 250, 250, 0.15);
}

.slider__progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(250, 250, 250, 0.4);
  transform: scaleX(0);
  transform-origin: top left;
  transition: 0.6s transform theme('transitionTimingFunction.out-quad');
}

/*------------------------------------*
  Sticky
*------------------------------------*/

@screen s {
  .sticky__figure {
    position: absolute;
    top: 0;
    width: 100%;
    transition: 0.6s clip-path theme('transitionTimingFunction.in-quad');
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);

    &.first {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  .sticky__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/*------------------------------------*
  link
*------------------------------------*/
.link {
  @apply text-sm font-light;
  position: relative;
  display: inline-block;
  padding: 1rem 0;
  overflow: hidden;
  filter: contrast(0.1);

  &:before {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: top right;
    transition: 0.6s transform theme('transitionTimingFunction.out-quad');
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: top left;
      transition: 0.6s transform theme('transitionTimingFunction.out-quad');
    }
  }
}

.link--menu {
  bottom: 0;
  padding: 0;
  overflow: initial;
  filter: none;

  @screen m {
    &:before {
      position: absolute;
      bottom: -1rem;
    }
  }

  &.is-active:before {
    transform: scaleX(1);
    transform-origin: top left;
  }
}

body[data-active-theme='light'] {
  .link--menu {
    @apply text-black;
  }
}

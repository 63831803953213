/**
  Menu
*/

.menu {
  transition: 0.6s transform theme('transitionTimingFunction.out-quad');
}

.menu__nav-holder:not(.translate-x-full) {
  li {
    animation-name: animateIn;
    animation-duration: 400ms;
    animation-timing-function: ease-in-out;
    animation-delay: calc(var(--animation-order) * 100ms);
    animation-fill-mode: both;
  }

  @screen m {
    animation: none;
  }
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
  }
}

/*------------------------------------*
  Figure
*------------------------------------*/
.figure {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    transform: scaleX(0.25);
    transform-origin: right;
  }
}

[data-option-theme='light'] {
  .figure:before {
    background-color: #fff;
  }
}

.figure__img {
  opacity: 0;
  transform: scale(1.1);
  transition:
    1.5s opacity theme('transitionTimingFunction.out-quad'),
    1s transform theme('transitionTimingFunction.out-quad');
}

.is-loaded {
  &.figure:before {
    transform: scaleX(0);
    transition: 1.5s transform theme('transitionTimingFunction.out-quad');
  }

  .figure__img {
    opacity: 1;
    transform: scale(1);
    transition:
      1.5s opacity theme('transitionTimingFunction.out-quad'),
      1s transform theme('transitionTimingFunction.out-quad');
  }
}

/* Magnet modifier
\*============================================================================*/

.magnetic-btn {
  position: relative;
  display: inline-block;
  padding: 1rem 2rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  border-radius: 36px;

  .magnetic-btn__bg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: theme('colors.black.medium');
    border-radius: 36px;
    transition: transform 0.2s ease;
  }

  .magnetic-btn__ripple {
    --size: 210%;

    position: absolute;
    display: block;
    width: var(--size);
    height: 0;
    margin-top: calc(var(--size) / -2);
    margin-left: calc(var(--size) / -2);
    padding-top: var(--size);
    background-color: #404040;
    border-radius: 50%;
    transform: translate(0, 0) scale(0);
  }

  .magnetic-btn__label {
    z-index: 1;
    position: relative;
    display: inline-block;
    color: theme('colors.white.DEFAULT');
    transition: transform 0.6s ease;
  }
}

.magnetic-btn--rounded {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.75rem;
  height: 2.75rem;
  padding: 0;

  @screen m {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.magnetic-btn--border {
  .magnetic-btn__bg {
    @apply border-2 border-white border-opacity-10;
    background-color: transparent;
  }
}

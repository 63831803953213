/*------------------------------------*
  Header
*------------------------------------*/
.header {
  position: relative;
}

.header--up {
  @screen m {
    .menu {
      transform: translateY(-184px);
      transition: 0.6s transform theme('transitionTimingFunction.out-quad');
    }
  }
}

/*------------------------------------*
  Card Project
*------------------------------------*/
.card-project {
  position: relative;

  a {
    // invisible element to extend clickable zone to the whole block
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }

  .figure {
    overflow: hidden;
  }

  .card-project__image-secondary {
    opacity: 0;
    transition: 500ms opacity theme('transitionTimingFunction.out-expo');
  }

  &:hover {
    .figure__img {
      transform: scale(1.1);
      transition: 1s transform theme('transitionTimingFunction.out-expo');
    }

    .card-project__image-secondary {
      opacity: 1;
    }
  }
}

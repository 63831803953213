/*============================================================================*\
  Atom Icon
\*============================================================================*/

.icon {
  display: inline-block;
  vertical-align: middle;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}

/* Block modifier
\*============================================================================*/

.icon--block {
  display: block;
}

/* Name modifier
\*============================================================================*/

.icon--name {
  svg {
    width: 1.5rem;
    height: calc(1.5rem * 400 / 100);
  }
}

/* Logos modifiers
\*============================================================================*/

.icon--logo,
.icon--github,
.icon--twitter,
.icon--linkedin,
.icon--instagram {
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.icon--fwa,
.icon--awwwards,
.icon--webby-award,
.icon--shopify-partner,
.icon--tiptoe,
.icon--psg,
.icon--galerie-lafayette,
.icon--fnac,
.icon--es {
  svg {
    width: auto;
    max-width: 100%;
    height: 3rem;
  }
}

.icon--heschung {
  svg {
    width: auto;
    height: 2rem;
  }
}

.icon--vins-alsace,
.icon--lelynx,
.icon--colgate,
.icon--bnp-paribas {
  svg {
    width: auto;
    height: 1.5rem;
  }
}

/* Disc & separator modifier
\*============================================================================*/

.icon--disc,
.icon--lang-separator {
  position: relative;
  width: 1.5em;
  height: 1.5em;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.25rem;
    height: 0.25rem;
    background-color: currentColor;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}

.icon--dot-separator {
  width: 1em;
  height: 1em;
}

body[data-active-theme='light'] {
  .header {
    color: #000;
  }

  svg {
    fill: #000;
  }
}

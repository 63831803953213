[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
  background: none;
  border-width: 0;
  outline: none !important;

  &::selection {
    @apply text-black bg-white;
  }
}

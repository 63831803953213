.scroll-indicator {
  width: 1px;
  height: 50vh;
  overflow: hidden;
  transform: translateY(-50%);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: currentColor;
    opacity: 0.1;
  }
}

.scroll-indicator__progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: currentColor;
  transition: 0.6s transform theme('transitionTimingFunction.out-expo');
}

body[data-active-theme='light'] {
  .scroll-indicator::before,
  .scroll-indicator__progress {
    @apply bg-black;
  }
}

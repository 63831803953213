/**
  Burger Btn
*/

.burger-btn {
  .burger-btn__icon {
    position: relative;
    display: block;
    width: 2.563rem;
    height: 8px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      display: block;
      height: 1px;
      background: currentColor;
      transition: transform 0.4s ease;
    }

    &:before {
      top: 0;
      width: 41px;
    }

    &:after {
      bottom: 0;
      left: initial;
      width: 21px;
    }
  }
}

.burger-btn.is-active {
  .burger-btn__icon {
    &:before {
      right: 0;
      left: initial;
      width: 25px;
      transform: translateY(2px) rotate(-45deg);
    }

    &:after {
      top: 0;
      right: 0;
      left: initial;
      width: 25px;
      transform: translateY(2px) rotate(45deg);
    }
  }
}

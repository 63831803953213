/*------------------------------------*
  Reveal
*------------------------------------*/
[data-component='Reveal'] {
  opacity: 0;

  .reveal__word {
    transform: translate3d(0px, 110%, 0px) rotate(0.001deg);
    transition: transform 1.2s cubic-bezier(0.15, 0.85, 0.45, 1);
  }

  .reveal__line {
    overflow: hidden;
  }

  // If splitting is by lines only
  &[data-option-type='lines'] {
    .reveal__line {
      overflow: visible;
      opacity: 0;
      transform: translate3d(0px, 110%, 0px) rotate(0.001deg);
      transition:
        transform 1.2s cubic-bezier(0.15, 0.85, 0.45, 1),
        opacity 0.8s cubic-bezier(0.32, 0.94, 0.6, 1);
    }
  }

  // visible state
  &.is-visible {
    opacity: 1;

    .reveal__word {
      transform: translate3d(0px, 0px, 0px) rotate(0.001deg);
    }

    &[data-option-type='lines'] {
      .reveal__line {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px) rotate(0.001deg);
      }
    }
  }
}

/*------------------------------------*
  Tag
*------------------------------------*/
.tag {
  @apply px-3 inline-block text-grey;
  height: 2.25rem;
  color: theme('colors.grey.DEFAULT');
  border: solid 2px #333;
  border-radius: 22px;

  // 0 width pseudo element to vertically align the text
  &:before {
    content: '';
    display: inline-block;
    height: 2.25rem;
    vertical-align: middle;
  }
}
